import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import { action, computed, makeObservable, override } from 'mobx';
import {
  AGENT_ROLES,
  BUYER_ROLES,
  BUYER_SIDE_ROLES,
  CLIENT_ROLES,
  LISTING_SIDE_ROLES,
  SELLER_ROLES,
  LISTING_TC,
  BUYER_TC,
  getRoleLabelsForTransaction,
} from 'src/models/transactions/roles';
import { ensureAddress } from 'src/stores/address-store';
import { Contact } from 'src/types/proto/contacts';
import { PartyState } from 'src/types/proto/transactions';
import type { PartyRole } from 'src/types/proto/transactions';
import { getColor } from 'src/utils/get-color';
import getFullName from 'src/utils/get-full-name';
import Item, { ItemStore, TransactionItemJson } from './item';

export type PartyJson = TransactionItemJson<'PARTY'>;

export const typeColorMap = {
  CLIENT: '#0BC18E',
  TEAM: getColor('blue-4'),
  CONTACT: getColor('darker-border-gray-color'),
};

export default class Party extends Item<'PARTY'> {
  constructor(store: ItemStore, json: PartyJson) {
    super(store, json);

    makeObservable(this);
  }

  @override
  updateFromJson(json: PartyJson) {
    super.updateFromJson(json);
    ensureAddress(this.contact.address);
    ensureAddress(this.contact.reAgent && this.contact.reAgent.address);
  }

  @computed
  get key() {
    return `party-${this.id}`;
  }

  @computed
  get isMe() {
    return this.store.parent.account.user.id === this.kindItem.userId;
  }

  @computed
  get hasMyEmail() {
    return this.store.parent.account.user.contact.email === this.email;
  }

  @computed
  get userId() {
    return this.kindItem.userId;
  }

  @computed
  get user() {
    return this.kindItem.user;
  }

  @computed
  get avatarUrl() {
    return this.contact.avatarUrl;
  }

  @computed
  get avatarUrlSm() {
    return this.contact.avatarUrl;
  }

  @computed
  get firstName() {
    return this.contact.firstName;
  }

  @computed
  get lastName() {
    return this.contact.lastName;
  }

  @computed
  get fullName() {
    return getFullName({
      firstName: this.firstName,
      lastName: this.lastName,
    });
  }

  @computed
  get email() {
    return this.contact.email ?? '';
  }

  @computed
  get phoneNumber() {
    return this.contact.cellPhone ?? '';
  }

  @computed
  get cellPhoneNumber() {
    return this.contact.cellPhone ?? '';
  }

  @computed
  get licenseNumber() {
    return this.contact.reAgent?.licenseNumber;
  }

  @computed
  get roles() {
    return this.kindItem.roles;
  }

  set roles(value) {
    this.kindItem.roles = value;
  }

  @computed
  get dsTokenSub() {
    return this.kindItem.dsTokenSub;
  }

  @computed
  get roleLabelPairs() {
    return this.roles.map((role) => [
      role,
      getRoleLabelsForTransaction(this.transaction)[role] || 'Unknown',
    ]);
  }

  @computed
  get contact() {
    return this.kindItem.contact as Contact;
  }

  set contact(value) {
    this.kindItem.contact = value;
  }

  @computed
  get partyContact() {
    return this.kindItem.partyContact;
  }

  set partyContact(value) {
    this.kindItem.partyContact = value;
    this.kindItem.contact = value;
  }

  setContact(contact: Contact) {
    this.kindItem.contact = contact;
  }

  get clientVisibility() {
    return this.kindItem.clientVisibility;
  }

  can(op: string) {
    const isEmbedded = Boolean(this.store.parent.embeddedApp?.isEmbedded);

    if (op === 'remove') {
      return (
        (!this.isMe || this.transaction.isTemplate) &&
        !(this.isUnbound && this.hasMyEmail) &&
        (!isEmbedded || !this.isBound) // Cannot remove bound parties on Glide embedded UX (BT<>Glide)
      );
    }
    if (op === 'leave') {
      /* Allow leaving transaction only for self and, if we are an agent or tc,
        there is another one on the side.
        Not show for templates where "remove" is more appropriate */
      // Cannot leave transaction on Glide embedded UX (BT<>Glide)
      // Cannot leave transaction on tmPartyModal enabled
      if (isEmbedded || !this.isMe || this.transaction.isTemplate) {
        return false;
      }
      const thisSideTeamParties = this.transaction.parties.thisSideTeamParties;
      const allowSelfLeaving =
        thisSideTeamParties.filter(
          (p) => p.userId !== this.store.parent.account.user.id
        ).length > 0;
      return allowSelfLeaving;
    }
    if (op === 'editEmail') {
      return (this.isUnbound && !this.hasMyEmail) || !!this.isPotential;
    }
    if (op === 'invite') {
      // Do not allow invites on embedded Glide UX (ie BT<>Glide)
      // Hide invite option on tmPartyModal enabled
      return !isEmbedded && !this.isBound && this.canInviteToTxn();
    }
    if (op === 'reinvite') {
      // Do not allow invites on embedded Glide UX (ie BT<>Glide)
      return !isEmbedded && !!this.isBoundUnregistered;
    }
    return false;
  }

  @computed
  get isPotential() {
    return this.kindItem?.isPotential;
  }

  @computed
  get isBoundUnregistered() {
    return this.isInvited || this.isPotential;
  }

  @computed
  get isInvited() {
    return this.kindItem.state === PartyState.INVITED;
  }

  @computed
  get isRegistered() {
    return this.kindItem.state === PartyState.ACTIVE;
  }

  @computed
  get isPending() {
    return this.kindItem.state === PartyState.PENDING;
  }

  @computed
  get isDeclined() {
    return this.kindItem.state === PartyState.DECLINED;
  }

  @computed
  get isBound() {
    return !!this.kindItem.userId;
  }

  @computed
  get isUnbound() {
    return !this.isBound;
  }

  @computed
  get isThisSideAgent() {
    return (
      intersection(this.roles, this.transaction.parties.thisSideAgentRoles)
        .length > 0
    );
  }

  @computed
  get isThisSideTeam() {
    return (
      intersection(this.roles, this.transaction.parties.thisSideTeamRoles)
        .length > 0
    );
  }

  @computed
  get partyType() {
    if (this.isBound) {
      if (intersection(this.roles, CLIENT_ROLES).length > 0) {
        return 'CLIENT';
      }
      return 'TEAM';
    }
    return 'CONTACT';
  }

  @computed
  get partyTypeColor() {
    return typeColorMap[this.partyType];
  }

  @computed
  get canRemove() {
    return !this.isMe;
  }

  @computed
  get isSeller() {
    return this.roles.some((r) => SELLER_ROLES.includes(r));
  }

  @computed
  get isBuyer() {
    return this.roles.some((r) => BUYER_ROLES.includes(r));
  }

  @computed
  get isClient() {
    return this.isSeller || this.isBuyer;
  }

  @computed
  get isCompassUser() {
    // TODO to change after https://compass-tech.atlassian.net/browse/TPB-343 is done
    return this.email.includes('@compass');
  }

  @computed
  get isTC() {
    const _roles = this.transaction.isListing ? LISTING_TC : BUYER_TC;
    return !this.isCompassUser && this.roles.some((r) => _roles === r);
  }

  @computed
  get isAgent() {
    return this.roles.some((r) => AGENT_ROLES.includes(r));
  }

  @computed
  get isPro() {
    return difference(this.roles, SELLER_ROLES, BUYER_ROLES).length > 0;
  }

  @computed
  get isInPackage() {
    return this.inEdgeIdsByKind('PACKAGE_HAS_PARTY').length > 0;
  }

  getRoute() {
    return {
      name: 'transactions.transaction.parties.party',
      params: {
        transactionId: this.transactionId,
        partyId: this.id,
      },
    };
  }

  canInvite(allowedRoles: string[]) {
    return (
      intersection(this.roles, allowedRoles).length > 0 &&
      !this.transaction.parties?.all.some((p) => {
        return (
          p.isRegistered &&
          p.email &&
          p.email.toLowerCase() === this.email.toLowerCase()
        );
      })
    );
  }

  canInviteToTxn() {
    const txn = this.transaction;
    if (txn.isPurchase) {
      return this.canInvite(BUYER_SIDE_ROLES);
    }
    if (txn.isListing) {
      return this.canInvite(LISTING_SIDE_ROLES);
    }
    return false;
  }

  @action
  updateContactDetails({
    roles,
    partyContact,
  }: {
    roles: PartyRole[];
    partyContact?: any;
  }) {
    this.roles = roles!;
    this.partyContact = {
      ...this.partyContact,
      ...partyContact,
    };
  }

  @computed
  get unbound() {
    return !!this.kindItem.unbound;
  }

  @computed
  get removed() {
    return !!this.kindItem.removed;
  }

  matchesQuery = (query: string) => {
    const terms = this.roles
      .map((r) => getRoleLabelsForTransaction(this.transaction)[r])
      .concat([this.fullName, this.email]);
    return terms.some((t) => t && t.toLowerCase().indexOf(query) !== -1);
  };
}
